

import wx from 'weixin-js-sdk'			//微信sdk依赖
import _this from '@/main.js'

const jsApiList = [
  'updateAppMessageShareData',
  'updateTimelineShareData',
  // 'showAllNonBaseMenuItem',
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  // 'onMenuShareQQ',
  // 'onMenuShareWeibo',
  // 'onMenuShareQZone',
  'openLocation'
]

//完成分享任务
async function task() {
  if (_this.$store.state.user.isLogin) {
    if (!_this.$store.state.user.taskList.length) await _this.$store.dispatch('user/getTaskQueryList')
    let wxTask = _this.$store.state.user.taskList[1].list[6]
    if (wxTask.times < wxTask.upLimit)
      await _this.$store.dispatch("user/taskFinished", wxTask.id);
  }

  _this.$toast.success('分享成功')
}
//获取JSSDK
export async function JSSDKready() {
  if (sessionStorage.getItem("JSSDK")) return
  // if (typeof WeixinJSBridge === "undefined") return
  await _this.$API.pay.wxShare({
    "object": {
      "id": `${window.location.origin}/`
    }
  }).then(res => {
    // console.log(res.data);
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //下面这里不用管  让后台看一下  让他返给你 
      appId: res.data.appid, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.noncestr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      jsApiList: jsApiList, // 必填，需要使用的JS接口列表
      openTagList: ['wx-open-launch-weapp']
    })
    sessionStorage.setItem("JSSDK", res.data.signature)
    // if (sessionStorage.getItem("JSSDK") == undefined) {
    //   sessionStorage.removeItem('JSSDK')
    // }
  })

}
// export async function testWx() {
//   wx.config({
//     debug: false,
// wx90363dc11c1572c0
//     appId: 'wx8845a1377cb25771',
//     timestamp: 1685415388,
//     nonceStr: '71517cc87fa74602b74a45744442f2c6',
//     signature: 'f9da0bc65e1d7dc2cd8a2bca786abf269d9413aa',
//     jsApiList: ['openLocation'],
//     openTagList: ['wx-open-launch-weapp']
//   });
// 5ed7b8a538315206dafb81ffb8b3ccf89e96a220
// }
//自定义分享内容
export async function WechatSharing(data = {
  title: "正品汇商城",
  desc: "正品汇认证交易平台，打破进口商、批发商、零售商的垄断地位，突破传统贸易的发展模式，重塑贸易结构，使零售商、批发商与企业直接接触，有效地减少中间环节，节约流动资本，提高交易效率。有需要防伪认证的产品都可以在平台进行交易，符合标准的产品将导入系统，并享有保险、金融等服务，平台对商品拥有全程的防伪溯源功能，可以充分保障消费者的权益。",
  imgUrl: require("@/assets/images/logo.png"),
  link: window.location.hash,
}) {

  if (typeof WeixinJSBridge != "undefined") {
    if (sessionStorage.getItem("JSSDK") == 'undefined')
      sessionStorage.removeItem('JSSDK')

    await JSSDKready()
    // wx.ready(function () {
    //   //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
    //   wx.updateAppMessageShareData({
    //     title: data.title,// 分享标题
    //     desc: data.desc,  // 分享描述
    //     link: `${window.location.origin}/${data.link}`, // 分享链接
    //     imgUrl: data.imgUrl, // 分享图标
    //     success: function success(res) {
    //       // 设置成功
    //     },
    //   });
    //   //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
    //   wx.updateTimelineShareData({
    //     title: data.title,
    //     link: `${window.location.origin}/${data.link}`,
    //     imgUrl: data.imgUrl,
    //     success: function () { }
    //   })
    // });

    //获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
    wx.onMenuShareAppMessage({
      title: data.title,
      desc: data.desc,
      link: `${window.location.origin}/${data.link}`,
      imgUrl: data.imgUrl,
      type: '', // 分享类型,music、video或link，不填默认为link
      dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空 
      success: function success(res) {
        // 用户点击了分享后执行的回调函数
        task()
      }
    });

    //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
    wx.onMenuShareTimeline({
      title: data.title,
      link: `${window.location.origin}/${data.link}`,
      imgUrl: data.imgUrl,
      success: function () {
        // 用户点击了分享后执行的回调函数
        task()
      }
    })
    wx.error(function (res) {
      // alert("微信验证失败");
    });

  }
  // })
}